<template>
  <div>
    <b-container class="bv-example-row ">
      <b-row class="justify-content-sm-center">
        <b-col cols="12" sm="4"
          ><div class="my-2 text-center"><logo /></div
        ></b-col>
      </b-row>
    </b-container>
    <b-container class="bv-example-row ">
      <b-row class="justify-content-sm-center text-center">
        <b-col cols="12" sm="4"
          ><b-link>
            <div class="p-1 border">
              <span
                style="position:absolute; top:55px; right:66px; color: white; font-size: 27px; font-weight: 700; text-decoration: underline;"
                >ป๊อกเด้ง99</span
              >
              <b-img
                fluid
                src="@core/assets/images/gameplay/live-casino.png"
              /></div></b-link
        ></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { BRow, BCol, BContainer, BImg, BLink } from 'bootstrap-vue'

import Logo from './component/Logo.vue'

export default {
  components: {
    Logo,
    BRow,
    BCol,
    BContainer,
    BImg,
    BLink
  },
  data() {
    return {
      sportUrl: '',
      hasSlotPromo: false
    }
  },
  async beforeMount() {
    // await this.getSportLogin()
    // await this.checkPromotion()
  },
  methods: {
    async getSportLogin() {
      const url = await this.$http.post('member/redirectLink', {
        page: 'sports'
      })
      console.log(url.data.url)
      this.sportUrl = url.data.url
    },
    async checkPromotion() {
      const resp = await this.$http.post('member/hasSlotPromotion')
      console.log(resp.data)
      this.hasSlotPromo = resp.data.status
    },
    showMsgBoxTwo(game) {
      if (this.hasSlotPromo === true) {
        this.$bvModal.msgBoxOk(
          `เนื่องจากลูกค้ารับโปรโมชั่น [สล็อต] ฝากครั้งแรก จึงยังไม่สามารถเข้าเล่นหมวดอื่นได้นอกจากสล็อต เมื่อลูกค้าเล่นโปรโมชั่นครบแล้วจะสามารถเข้าเล่นคาสิโนได้ปรกติค่ะ`,
          {
            title: 'แจ้งเตือน',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'ยืนยัน',
            hideHeaderClose: false,
            centered: true
          }
        )
      } else {
        window.location.href = game
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.border {
  border: 3px solid;
  border-color: #39aea9 !important;
  margin-bottom: 4px;
}
</style>
